import React, { useContext } from 'react'
import './style.css'
import { ThemeContext } from '../../context/theme'

interface PageHeaderProps {
    backgroundImage: string
    header: string
    subHeader: string
}

export default ({ backgroundImage, header, subHeader }: PageHeaderProps) => {
    const theme = useContext(ThemeContext)
    return (
        <section
            className="page-header container-fluid"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="container">
                <div className="text-container">
                    <h2>{header}</h2>
                    <h3 style={{ color: theme.color.secondary }}>
                        {subHeader}
                    </h3>
                </div>
            </div>
        </section>
    )
}

import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import PageHeader from '../components/PageHeader'
import HouseAndLand from '../views/HouseAndLand'
import { HousePackage } from '../types/HomeDesigns'
import { Houses } from '../types/ExternalApi'
import mapHousePackageRequest from '../utils/mapHousePackageRequest'
import SubscribeForm from '../components/SubscribeForm'
import { SiteConfig } from '../types/ContentBuilder'

const backgroundImage = require('../images/rectangle_two.png')

const defaultTheme = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface HLPackagesProps {
    pageContext: {
        housePackages: Houses[]
        config: SiteConfig
    }
}

export default ({ pageContext }: HLPackagesProps) => {
    const houses: HousePackage[] = pageContext.housePackages.map(house =>
        mapHousePackageRequest(house)
    )
    return (
        <ThemeProvider value={defaultTheme}>
            <Layout>
                <SEO title="H&L Packages" />
                <PageHeader
                    backgroundImage={backgroundImage}
                    header={'House & Land Packages, ready to live in.'}
                    subHeader={'Find your community'}
                />
                <HouseAndLand houses={houses} />
                <SubscribeForm
                    theme={defaultTheme}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}

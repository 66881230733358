import React, { useState } from 'react'
import HousesListView from './HouseListView'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import { HousePackage } from '../../../types/HomeDesigns'
import googleMapsStyling from '../../../utils/googleMapsStyling'
import { ThemeOptions } from '../../../types'
import MapItem from './MapItem'

const mapMarker = require('../../../images/map-marker.png')

interface MapViewProps {
    google: any
    houses: HousePackage[]
    theme: ThemeOptions
}

export const MapView = ({ google, houses, theme }: MapViewProps) => {
    const [housePackageInfo, setHousePackageInfo] = useState<HousePackage>(
        houses[0]
    )
    const [showMapItem, setShowMapItem] = useState<boolean>(false)
    return (
        <div className="main-maps-container">
            <HousesListView houses={houses} theme={theme} />
            <div className="maps-container">
                <Map
                    google={google}
                    styles={googleMapsStyling as any}
                    initialCenter={{ lat: -37.8167899, lng: 144.9628745 }}
                    zoom={9}
                >
                    {houses.map(house => (
                        <Marker
                            position={{
                                lat: house.location.lat,
                                lng: house.location.lng,
                            }}
                            icon={mapMarker}
                            onClick={() => {
                                setHousePackageInfo(house)
                                setShowMapItem(true)
                            }}
                        />
                    ))}
                </Map>
            </div>
            <MapItem
                house={housePackageInfo}
                show={showMapItem}
                setShow={setShowMapItem}
                theme={theme}
            />
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyASeDJruEg909cMDOHMrswrgf5gz2niNC4',
})(MapView)

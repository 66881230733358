import React from 'react'
import './style.css'

const downloadImage = require('./icon-download.png')

interface DownloadLinkProps {
    url?: string | false
}

export default ({ url }: DownloadLinkProps) => (
    <div className="common download-link">
        <a
            href={
                url
                    ? url
                    : 'http://adminpdx.sonardev.com.au/app/uploads/2020/02/6108_PDX-Product-Catalogue-Brochure_v111-4.pdf'
            }
            className="flyer"
            target="_blank"
        >
            <div
                className="image"
                style={{
                    backgroundImage: `url(${downloadImage})`,
                }}
            />
            <span>View flyer</span>
        </a>
    </div>
)

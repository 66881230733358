import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../../context/theme'
import './style.css'
import { HousePackage } from '../../types/HomeDesigns'
import MapView from './MapView'
import SortButton from '../../components/SortButton'
import { SortState } from '../../types'

interface HouseAndLandProps {
    houses: HousePackage[]
}

export default ({ houses }: HouseAndLandProps) => {
    const theme = useContext(ThemeContext)
    const [sortState, setSortState] = useState<number>(0)
    const [sortedHouses, setSortedHouses] = useState<HousePackage[]>(houses)
    const setNewSortState = (newSortState: SortState) => {
        if (newSortState === SortState.LowToHigh)
            sortedHouses.sort((a, b) => parseInt(a.price) - parseInt(b.price))
        else if (newSortState === SortState.HighToLow)
            sortedHouses.sort((a, b) => parseInt(b.price) - parseInt(a.price))
        setSortedHouses(sortedHouses)
        setSortState(newSortState)
    }
    useEffect(() => {
        setNewSortState(SortState.LowToHigh)
    }, [])
    return (
        <>
            {/* <Filter options={{ theme }} /> */}
            <section className="house-land">
                <div
                    className="sorting-container"
                    style={{
                        backgroundImage: `linear-gradient(to right, ${theme.color.primary}, ${theme.color.secondary})`,
                    }}
                >
                    <p>Viewing {houses.length} House & Land Packages</p>
                    <SortButton
                        options={{ theme }}
                        sortState={sortState}
                        setSortState={setNewSortState}
                    />
                </div>
                <MapView houses={sortedHouses} theme={{ theme }} />
            </section>
        </>
    )
}

import React from 'react'
import { HousePackage } from '../../../types/HomeDesigns'
import RoomList from '../../../components/Common/RoomList'
import { ThemeOptions } from '../../../types'
import Button from '../../../components/Common/Button'
import formatHousePrice from '../../../utils/formatHousePrice'
import { navigate } from 'gatsby'

interface HouseListItemProps {
    house: HousePackage
    theme: ThemeOptions
}

export default ({ house, theme }: HouseListItemProps) => (
    <li
        className="house-list-item"
        onClick={() => navigate(`/house-and-land-packages/${house.id}`)}
    >
        <div className="content">
            <div
                className="item-image-container"
                style={{ backgroundImage: `url(${house.previewImage})` }}
            />
            <div className="item-content-container">
                <h3 style={{ color: theme.theme.color.primary }}>
                    {house.name}
                </h3>
                <p>
                    <span style={{ color: theme.theme.color.secondary }}>
                        Lot {house.lotNumber} {house.streetName},
                    </span>{' '}
                    {house.suburb}
                </p>
                <RoomList rooms={house.rooms} />
                <span className="price">${formatHousePrice(house.price)}</span>
            </div>
        </div>
        <div className="house-list-button">
            <Button
                label={'View House & Land Package'}
                onClick={() => {}}
                style={{ backgroundColor: theme.theme.color.secondary }}
            />
        </div>
    </li>
)

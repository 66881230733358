import {
    RoomType,
    HousePackage,
    HousePackageRequest,
} from '../types/HomeDesigns'

export default (request: HousePackageRequest) => {
    let location = { lat: 0, lng: 0 }
    if (request.acf.location) {
        location = {
            lat: request.acf.location.lat ? request.acf.location.lat : 0,
            lng: request.acf.location.lng ? request.acf.location.lng : 0,
        }
    }
    const suburb =
        request.acf.suburb.charAt(0).toUpperCase() + request.acf.suburb.slice(1)
    const estateName = request.acf.estate_name.split(' ')[0]
    const deepLink =
        'https://shop.pdxhomes.com.au/?step=package_details&package_id=' +
        request.acf.package_id
    return {
        id: request.slug,
        backgroundImage: request.image,
        description: request.stripped_content,
        previewImage: request.image_optimised,
        name: request.acf.title,
        price: request.acf.package_price,
        suburb,
        estateName,
        size: {
            size: parseInt(request.acf.lot_size),
            lotDepth: parseInt(request.acf.lot_depth),
            lotWidth: parseInt(request.acf.lot_width),
        },
        location,
        floorplan: {
            ground: request.acf.floorplan_g
                ? request.acf.floorplan_g.url
                : false,
            first: request.acf.floorplan_1
                ? request.acf.floorplan_1.url
                : false,
        },
        lotNumber: parseInt(request.acf.lot_number),
        streetName: request.acf.street_name,
        deepLink,
        rooms: {
            bedroom: {
                count: parseInt(request.acf.bedrooms),
                type: RoomType.Bedroom,
            },
            living_room: {
                count: parseInt(request.acf.living_areas),
                type: RoomType.LivingRoom,
            },
            bathroom: {
                count: parseInt(request.acf.bathrooms),
                type: RoomType.Bathroom,
            },
            garage: {
                count: parseInt(request.acf.cars),
                type: RoomType.Garage,
            },
        },
    } as HousePackage
}

export interface ThemeOptions {
    theme?: {
        color?: {
            primary?: string
            secondary?: string
        }
    }
}
export interface SiteConfig {
    BASE_SITE_URL: string
    RECAPTCHA: string
    API_ENDPOINT: string
    GRAVITY_CONSUMER_KEY: string
    GRAVITY_CONSUMER_SECRET: string
    CMS_ENDPOINT: string
}

export type SectionComponent = {
    component: ({
        props,
        theme,
        config,
    }: {
        props: any
        theme: Theme
        config: SiteConfig
    }) => JSX.Element
    props: any
}
export type ContentBuilderTheme = {
    primary_text_color: string
    header_text_color: string
    secondary_text_color: string
    third_text_color: string
}

export type Theme = {
    color: {
        primary: string
        secondary: string
        third: string
    }
}

export type Testimonial = {
    name: string
    title: string
    image: string
    paragraph: string
}

export type Floorplan = {
    title: string
    dimensions: {
        size: number
        minWidth: number
        minDepth: number
    }
    images: {
        groundFloor: string | false
        firstFloor: string | false
    }
}

export type OpeningHour = {
    label: string
    hour: string
}

export type PercentageDTO = {
    label: string
    value: number
}

export enum SortState {
    LowToHigh,
    HighToLow,
}

export type Image = { url: string }
export type GalleryDTO = Image[]

export type HighlightDTO = {
    image: string
    title: string
    paragraph: string
}
export type VirtualTourDTO = {
    title: string
    image: string
    link: string
}
export type FlyerDTO = string

export type InputFieldDTO = {
    field: string
    type: InputFieldTypes
    required: boolean
}
export type InputFieldTypes = 'email' | 'tel' | 'text' | 'hidden'

export interface AdvicesDTO {
    title: string
    image: string
    category: number
    url: string
}
export interface CategoriesDTO {
    id: number
    name: string
    slug: string
}

export interface FloorplanDTO {
    ground: string | false
    first: string | false
}

import React, { useState } from 'react'
import { HousePackage } from '../../../../types/HomeDesigns'
import './style.css'
import RoomList from '../../../../components/Common/RoomList'
import DownloadLink from '../../../../components/Common/DownloadLink'
import { ThemeOptions } from '../../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import formatHousePrice from '../../../../utils/formatHousePrice'
import calculateMonthlyFee from '../../../../utils/calculateMonthlyFee'

interface MapItemProps {
    house: HousePackage
    show: boolean
    setShow: Function
    theme: ThemeOptions
}

export default ({ house, show, setShow, theme }: MapItemProps) => {
    const housePrice = formatHousePrice(house.price)
    const weeklyFee = calculateMonthlyFee(house.price)
    const [onButtonHover, setOnButtonHover] = useState<boolean>(false)
    return (
        <div
            className="map-item"
            style={{
                opacity: show ? 1 : 0,
                visibility: show ? 'visible' : 'hidden',
            }}
        >
            <div
                className="image"
                style={{ backgroundImage: `url(${house.backgroundImage})` }}
            />
            <div
                className="close"
                style={{ backgroundColor: theme.theme.color.secondary }}
                onClick={() => {
                    setShow(false)
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="map-item-content-container">
                <h2 style={{ color: theme.theme.color.primary }}>
                    {house.name}
                </h2>
                <RoomList rooms={house.rooms} />
                <h3>
                    <span style={{ color: theme.theme.color.secondary }}>
                        Lot {house.lotNumber} {house.streetName},{' '}
                    </span>
                    {house.suburb}
                </h3>
                <div className="price-container">
                    <h4 style={{ color: theme.theme.color.primary }}>
                        House & Land Package
                    </h4>
                    <span className="price">
                        from <span className="red">${housePrice}</span>
                    </span>
                    <span className="monthly-price">
                        or <span className="red">${weeklyFee}</span> a week
                    </span>
                </div>
                <DownloadLink />
            </div>
            <Link
                to={`/house-and-land-packages/${house.id}`}
                onMouseEnter={() => setOnButtonHover(true)}
                onMouseLeave={() => setOnButtonHover(false)}
                style={{
                    backgroundColor: onButtonHover
                        ? theme.theme.color.primary
                        : theme.theme.color.secondary,
                }}
            >
                View House & Land Package
            </Link>
        </div>
    )
}

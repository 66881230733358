import React from 'react'
import { HousePackage } from '../../../types/HomeDesigns'
import HouseListItem from './HouseListItem'
import './style.css'
import { ThemeOptions } from '../../../types'

interface HousesListViewProps {
    houses: HousePackage[]
    theme: ThemeOptions
}

export default ({ houses, theme }: HousesListViewProps) => {
    return (
        <ul className="content-container">
            {houses.map((house, index) => (
                <HouseListItem house={house} key={index} theme={theme} />
            ))}
        </ul>
    )
}

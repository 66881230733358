import React from 'react'
import Button from '../Common/Button'
import { ThemeOptions } from '../../types'

const stateLabel = ['Price (Low to High)', 'Price (High to Low)']

interface SortButtonProps {
    sortState: number
    setSortState: Function
    options?: ThemeOptions
}

export default ({ sortState, setSortState, options }: SortButtonProps) => (
    <Button
        label={stateLabel[sortState]}
        onClick={() => setSortState(!sortState ? 1 : 0)}
        style={{ color: 'white' }}
        onHoverStyle={{ color: options.theme.color.secondary }}
    />
)
